.register-page {
	display: flex;
	justify-content: center;
	align-items: center;
	min-height: 100vh;
	background-color: #f5f5f5;
	padding: 1rem;
}

.register-container {
	display: flex;
	flex-direction: row;
	width: 100%;
	max-width: 800px;
	box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
	border-radius: 8px;
	background-color: #fff;
	overflow: hidden;
}

.register-card {
	flex: 1;
	padding: 2rem;
	background-color: #ffffff;
	text-align: center;
}

.register-card h1 {
	font-size: 24px;
	color: #333;
	margin-bottom: 0.5rem;
}

.register-card p {
	color: #777;
	margin-bottom: 1.5rem;
}

.form-group {
	margin-bottom: 1rem;
	text-align: left;
}

.form-group label {
	font-size: 0.875rem;
	color: #333;
}

.form-group input {
	width: 100%;
	padding: 0.5rem;
	font-size: 1rem;
	border: 1px solid #ccc;
	border-radius: 4px;
	margin-top: 0.25rem;
}

.form-group input.error {
	border-color: #ff4d4f;
}

.error-text {
	color: #ff4d4f;
	font-size: 0.75rem;
	margin-top: 0.25rem;
	display: block;
}

button[type="submit"] {
	width: 100%;
	padding: 0.75rem;
	font-size: 1rem;
	color: #fff;
	background-color: #007bff;
	border: none;
	border-radius: 4px;
	cursor: pointer;
	margin-top: 1rem;
	transition: background-color 0.3s ease;
}

button[type="submit"]:hover {
	background-color: #0056b3;
}

.secondary-button {
	width: 100%;
	padding: 0.75rem;
	font-size: 1rem;
	color: #007bff;
	background: none;
	border: 1px solid #007bff;
	border-radius: 4px;
	cursor: pointer;
	margin-top: 0.5rem;
	transition: color 0.3s ease, background-color 0.3s ease;
}

.secondary-button:hover {
	color: #0056b3;
	border-color: #0056b3;
}

button:disabled {
	background-color: #ccc;
	cursor: not-allowed;
}

.info-card {
	flex: 1;
	background-color: #003366;
	color: white;
	padding: 2rem;
	display: flex;
	flex-direction: column;
	justify-content: center;
	text-align: left;
}

.info-card h2 {
	font-size: 24px;
	margin-bottom: 1rem;
}

.info-card p {
	font-size: 1rem;
	line-height: 1.5;
	margin-bottom: 1rem;
}

.subtitle {
    font-size: 0.8rem;
    
}