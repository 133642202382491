.login-page {
	display: flex;
	justify-content: center;
	align-items: center;
	min-height: 100vh;
	background-color: #f5f5f5;
}

.login-card {
	background: #ffffff;
	padding: 2rem;
	width: 100%;
	max-width: 400px;
	box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
	border-radius: 8px;
	text-align: center;
}

.login-card h1 {
	font-size: 24px;
	color: #333;
	margin-bottom: 0.5rem;
}

.login-card p {
	color: #777;
	margin-bottom: 1.5rem;
}

.form-group {
	margin-bottom: 1rem;
	text-align: left;
}

.form-group label {
	font-size: 0.875rem;
	color: #333;
}

.form-group input {
	width: 100%;
	padding: 0.5rem;
	font-size: 1rem;
	border: 1px solid #ccc;
	border-radius: 4px;
	margin-top: 0.25rem;
}

.form-group input.error {
	border-color: #ff4d4f;
}

.error-text {
	color: #ff4d4f;
	font-size: 0.75rem;
	margin-top: 0.25rem;
	display: block;
}

.forgot-password {
	width: 100%;
	padding: 0.5rem;
	font-size: 0.875rem;
	color: #007bff;
	background: none;
	border: none;
	cursor: pointer;
	text-align: left;
	margin: 0.5rem 0;
}

button[type="submit"] {
	width: 100%;
	padding: 0.75rem;
	font-size: 1rem;
	color: #fff;
	background-color: #071e37;
	border: none;
	border-radius: 4px;
	cursor: pointer;
	margin-top: 1rem;
	transition: background-color 0.3s ease;
}

button[type="submit"]:hover {
	background-color: #0056b3;
}

.primary-button {
	width: 100%;
	padding: 0.75rem;
	font-size: 1rem;
	color: #fff;
	background-color: #071e37 !important;
	border: none;
	border-radius: 4px;
	cursor: pointer;
	margin-top: 0.5rem;
	transition: background-color 0.3s ease;
}

.primary-button:hover {
	background-color: #0056b3 !important;
}

.secondary-button {
	width: 100%;
	padding: 0.75rem;
	font-size: 1rem;
	color: #071e37 !important;
	background: none;
	border: 2px solid #071e37 !important;
	border-radius: 4px;
	cursor: pointer;
	margin-top: 0.5rem;
	transition: color 0.3s ease, background-color 0.3s ease;
}

.secondary-button:hover {
	color: #0056b3;
	border-color: #0056b3;
}

button:disabled {
	background-color: #ccc;
	cursor: not-allowed;
}
