/* @font-face {
	font-family: "Montserrat";
	src: url("assets/fonts/Montserrat-VariableFont_wght.ttf");
} */



.text-black {
	color: #000;
}

.light-text {
	font-weight: 200; /* Lighter weight */
}

.bold-text {
	font-weight: 800; /* Bolder weight */
}
