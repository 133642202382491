@import url(https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,100..900;1,100..900&family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap);


body {
	margin: 0 !important;
	padding: 0 !important;
	font-family: "Jost", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-rendering: optimizeLegibility;
}
h1,
h2,
h3,
h4,
h5 {
	font-family: "Jost", sans-serif;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

.overline {
	text-transform: uppercase;
	color: rgb(155, 155, 155);
	margin-bottom: 0;
	font-weight: bolder;
}

.text-uppercase {
	text-transform: uppercase;
}

.MuiTypography-root {
	font-family: "Jost", sans-serif;
	font-weight: bold;
}

.text-bold {
	font-weight: 700;
}

.fadeInUp {
	-webkit-animation: fadeInUp 0.5s ease-in-out;
	        animation: fadeInUp 0.5s ease-in-out;
}

@-webkit-keyframes fadeInUp {
	from {
		opacity: 0;
		-webkit-transform: translateY(20px);
		        transform: translateY(20px);
	}
	to {
		opacity: 1;
		-webkit-transform: translateY(0);
		        transform: translateY(0);
	}
}

@keyframes fadeInUp {
	from {
		opacity: 0;
		-webkit-transform: translateY(20px);
		        transform: translateY(20px);
	}
	to {
		opacity: 1;
		-webkit-transform: translateY(0);
		        transform: translateY(0);
	}
}

.fadeInUp {
	-webkit-animation-name: fadeInUp;
	        animation-name: fadeInUp;
	-webkit-animation-duration: 0.5s;
	        animation-duration: 0.5s; /* Adjust duration as needed */
	-webkit-animation-fill-mode: both;
	        animation-fill-mode: both; /* Retain the final keyframe state */
}

.fadeInUp.enter {
	opacity: 1;
	-webkit-transform: translateY(0px);
	        transform: translateY(0px);
}

/* @font-face {
	font-family: "Montserrat";
	src: url("assets/fonts/Montserrat-VariableFont_wght.ttf");
} */



.text-black {
	color: #000;
}

.light-text {
	font-weight: 200; /* Lighter weight */
}

.bold-text {
	font-weight: 800; /* Bolder weight */
}

.login-page {
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: center;
	        justify-content: center;
	-webkit-align-items: center;
	        align-items: center;
	min-height: 100vh;
	background-color: #f5f5f5;
}

.login-card {
	background: #ffffff;
	padding: 2rem;
	width: 100%;
	max-width: 400px;
	box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
	border-radius: 8px;
	text-align: center;
}

.login-card h1 {
	font-size: 24px;
	color: #333;
	margin-bottom: 0.5rem;
}

.login-card p {
	color: #777;
	margin-bottom: 1.5rem;
}

.form-group {
	margin-bottom: 1rem;
	text-align: left;
}

.form-group label {
	font-size: 0.875rem;
	color: #333;
}

.form-group input {
	width: 100%;
	padding: 0.5rem;
	font-size: 1rem;
	border: 1px solid #ccc;
	border-radius: 4px;
	margin-top: 0.25rem;
}

.form-group input.error {
	border-color: #ff4d4f;
}

.error-text {
	color: #ff4d4f;
	font-size: 0.75rem;
	margin-top: 0.25rem;
	display: block;
}

.forgot-password {
	width: 100%;
	padding: 0.5rem;
	font-size: 0.875rem;
	color: #007bff;
	background: none;
	border: none;
	cursor: pointer;
	text-align: left;
	margin: 0.5rem 0;
}

button[type="submit"] {
	width: 100%;
	padding: 0.75rem;
	font-size: 1rem;
	color: #fff;
	background-color: #071e37;
	border: none;
	border-radius: 4px;
	cursor: pointer;
	margin-top: 1rem;
	transition: background-color 0.3s ease;
}

button[type="submit"]:hover {
	background-color: #0056b3;
}

.primary-button {
	width: 100%;
	padding: 0.75rem;
	font-size: 1rem;
	color: #fff;
	background-color: #071e37 !important;
	border: none;
	border-radius: 4px;
	cursor: pointer;
	margin-top: 0.5rem;
	transition: background-color 0.3s ease;
}

.primary-button:hover {
	background-color: #0056b3 !important;
}

.secondary-button {
	width: 100%;
	padding: 0.75rem;
	font-size: 1rem;
	color: #071e37 !important;
	background: none;
	border: 2px solid #071e37 !important;
	border-radius: 4px;
	cursor: pointer;
	margin-top: 0.5rem;
	transition: color 0.3s ease, background-color 0.3s ease;
}

.secondary-button:hover {
	color: #0056b3;
	border-color: #0056b3;
}

button:disabled {
	background-color: #ccc;
	cursor: not-allowed;
}

.register-page {
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: center;
	        justify-content: center;
	-webkit-align-items: center;
	        align-items: center;
	min-height: 100vh;
	background-color: #f5f5f5;
	padding: 1rem;
}

.register-container {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: row;
	        flex-direction: row;
	width: 100%;
	max-width: 800px;
	box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
	border-radius: 8px;
	background-color: #fff;
	overflow: hidden;
}

.register-card {
	-webkit-flex: 1 1;
	        flex: 1 1;
	padding: 2rem;
	background-color: #ffffff;
	text-align: center;
}

.register-card h1 {
	font-size: 24px;
	color: #333;
	margin-bottom: 0.5rem;
}

.register-card p {
	color: #777;
	margin-bottom: 1.5rem;
}

.form-group {
	margin-bottom: 1rem;
	text-align: left;
}

.form-group label {
	font-size: 0.875rem;
	color: #333;
}

.form-group input {
	width: 100%;
	padding: 0.5rem;
	font-size: 1rem;
	border: 1px solid #ccc;
	border-radius: 4px;
	margin-top: 0.25rem;
}

.form-group input.error {
	border-color: #ff4d4f;
}

.error-text {
	color: #ff4d4f;
	font-size: 0.75rem;
	margin-top: 0.25rem;
	display: block;
}

button[type="submit"] {
	width: 100%;
	padding: 0.75rem;
	font-size: 1rem;
	color: #fff;
	background-color: #007bff;
	border: none;
	border-radius: 4px;
	cursor: pointer;
	margin-top: 1rem;
	transition: background-color 0.3s ease;
}

button[type="submit"]:hover {
	background-color: #0056b3;
}

.secondary-button {
	width: 100%;
	padding: 0.75rem;
	font-size: 1rem;
	color: #007bff;
	background: none;
	border: 1px solid #007bff;
	border-radius: 4px;
	cursor: pointer;
	margin-top: 0.5rem;
	transition: color 0.3s ease, background-color 0.3s ease;
}

.secondary-button:hover {
	color: #0056b3;
	border-color: #0056b3;
}

button:disabled {
	background-color: #ccc;
	cursor: not-allowed;
}

.info-card {
	-webkit-flex: 1 1;
	        flex: 1 1;
	background-color: #003366;
	color: white;
	padding: 2rem;
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
	-webkit-justify-content: center;
	        justify-content: center;
	text-align: left;
}

.info-card h2 {
	font-size: 24px;
	margin-bottom: 1rem;
}

.info-card p {
	font-size: 1rem;
	line-height: 1.5;
	margin-bottom: 1rem;
}

.subtitle {
    font-size: 0.8rem;
    
}
.response-text {
	font-size: 1.6em;
	color: gray;
	font-weight: bold;
	padding-bottom: 0px;
	margin-bottom: 0px;
}

.player-text {
	padding-top: 0px;
	margin-top: 0px;
	margin-left: 8px;
	margin-bottom: 0px;
}

.email-text {
	font-size: 0.8;
	color: gray;
	margin-bottom: 7px;
	margin-top: 0px;
	margin-left: 8px;
}

.tr-table {
	border-bottom: 1px solid #ddd;
	width: 100%;
	text-align: left;
	padding: 12px;
	border: #dfdfdf 1px solid;

	tr {
		td {
			padding: 12px;
			text-align: left;
		}

		th {
			padding: 12px;
			background-color: #eaeaeab9;

			text-align: left;
			font-size: 1.1em;
			font-family: "Roboto", sans-serif;
			font-weight: 600;
			text-transform: uppercase;
		}
	}
}

