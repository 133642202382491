

@import url('https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,100..900;1,100..900&family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');


body {
	margin: 0 !important;
	padding: 0 !important;
	font-family: "Jost", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-rendering: optimizeLegibility;
}
h1,
h2,
h3,
h4,
h5 {
	font-family: "Jost", sans-serif;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

.overline {
	text-transform: uppercase;
	color: rgb(155, 155, 155);
	margin-bottom: 0;
	font-weight: bolder;
}

.text-uppercase {
	text-transform: uppercase;
}

.MuiTypography-root {
	font-family: "Jost", sans-serif;
	font-weight: bold;
}

.text-bold {
	font-weight: 700;
}

.fadeInUp {
	animation: fadeInUp 0.5s ease-in-out;
}

@keyframes fadeInUp {
	from {
		opacity: 0;
		transform: translateY(20px);
	}
	to {
		opacity: 1;
		transform: translateY(0);
	}
}

.fadeInUp {
	animation-name: fadeInUp;
	animation-duration: 0.5s; /* Adjust duration as needed */
	animation-fill-mode: both; /* Retain the final keyframe state */
}

.fadeInUp.enter {
	opacity: 1;
	transform: translateY(0px);
}
