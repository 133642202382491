.response-text {
	font-size: 1.6em;
	color: gray;
	font-weight: bold;
	padding-bottom: 0px;
	margin-bottom: 0px;
}

.player-text {
	padding-top: 0px;
	margin-top: 0px;
	margin-left: 8px;
	margin-bottom: 0px;
}

.email-text {
	font-size: 0.8;
	color: gray;
	margin-bottom: 7px;
	margin-top: 0px;
	margin-left: 8px;
}

.tr-table {
	border-bottom: 1px solid #ddd;
	width: 100%;
	text-align: left;
	padding: 12px;
	border: #dfdfdf 1px solid;

	tr {
		td {
			padding: 12px;
			text-align: left;
		}

		th {
			padding: 12px;
			background-color: #eaeaeab9;

			text-align: left;
			font-size: 1.1em;
			font-family: "Roboto", sans-serif;
			font-weight: 600;
			text-transform: uppercase;
		}
	}
}
